import {_request} from '../functions'

class order {
  constructor() {
    if (!this.setVars()) return
    this.setEvents()
  }

  setVars() {
    this.donate_btns = document.body.querySelectorAll('[data-donate]');
    return this.donate_btns.length
  }

  setEvents() {
    this.singlePay()
    this.chargePay()
  }

  singlePay() {
    let price, desc, name, data
    each(this.donate_btns, (key, val) => {
      val.addEventListener('click', (e) => {
        e.preventDefault()
        price = sanitize(val.dataset.price)
        desc = sanitize(val.dataset.desc)
        name = sanitize(val.dataset.name)
        if (!price) return;
        data = {
          unitPrice: price,
          productName: name,
          description: desc,
          _token: csrf
        }
        _request('api/order', JSON.stringify(data), {
          cb: (xhr) => {
            xhr.json().then(response => {
              if (!response.redirectUri) return console.error('invalid redirectUri')
              //window.location.href = response.redirectUri;
            })
          }
        });
      })
    })
  }

  chargePay(btn) {
    if(!btn) return;
    console.log('charge pay')
    let price, data;
    btn.addEventListener('click', (e) => {
      e.preventDefault()
      price = sanitize(val.dataset.price)
      if (!price) return;
      data = {
        unitPrice: price,
        productName: name,
        description: desc,
        _token: csrf
      }
      _request('api/order', JSON.stringify(data), {
        cb: (xhr) => {
          xhr.json().then(response => {
            if (!response.redirectUri) return console.error('invalid redirectUri')
            window.location.href = response.redirectUri;
          })
        }
      });
    })
  }
}

export default order;
