import flatpickr from 'flatpickr';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

class inputCalendar {
  constructor() {
    if (!this.setVars()) {
      return;
    }
    this.setEvents()
  }

  setVars() {
    this.inputs = document.body.querySelectorAll('[data-calendar]')
    return this.inputs.length
  }

  setEvents() {
    let datePicker;
    each(this.inputs, (key, val) => {
      flatpickr(val, {
        locale: {
          weekdays: {
            shorthand: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
            longhand: [
              'Niedziela',
              'Poniedziałek',
              'Wtorek',
              'Środa',
              'Czwartek',
              'Piątek',
              'Sobota',
            ],
          },
          months: {
            shorthand: [
              'Sty',
              'Lut',
              'Mar',
              'Kwi',
              'Maj',
              'Cze',
              'Lip',
              'Sie',
              'Wrz',
              'Paź',
              'Lis',
              'Gru',
            ],
            longhand: [
              'Styczeń',
              'Luty',
              'Marzec',
              'Kwiecień',
              'Maj',
              'Czerwiec',
              'Lipiec',
              'Sierpień',
              'Wrzesień',
              'Październik',
              'Listopad',
              'Grudzień',
            ],
          },
          rangeSeparator: ' / ',
          weekAbbreviation: 'tydz.',
          scrollTitle: 'Przewiń, aby zwiększyć',
          toggleTitle: 'Kliknij, aby przełączyć',
          firstDayOfWeek: 1,
          ordinal() {
            return '-';
          },
        },
        plugins: val.dataset.plugin ? [
          new monthSelectPlugin({
            shorthand: true, //defaults to false
            dateFormat: "Y-m", //defaults to "F Y"
            theme: "light" // defaults to "light"
          })
        ] : false,
        mode: val.dataset.type || 'single',
        enableTime: false,
        time_24hr: true,
        dateFormat: 'Y-m-d',
        //minDate: new Date(),
        maxDate: new Date(),
        defaultDate: val.dataset.calendar,
        onClose: (selectedDates, dateStr, instance) => {
          console.log(dateStr)
          if(!val.dataset.api) return
          request(`${val.dataset.api}`, JSON.stringify({date: dateStr, type: val.dataset.type || 'single'}), {
            cb: (xhr) => {
              xhr.json().then(json => {
                //window.location.reload()
                window.location.href = window.location.href
              });
            }
          });
        },
      });
    });
  }
}

export default inputCalendar;
