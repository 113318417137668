export function _validateForm(els, data, targetBtn, exclude = []) {
  let arr = [];

  each(els, (key, val) => {
    val.classList.remove('alert');
    del(val.parentNode.querySelector(`[data-key="warn${key}"]`));

    const min = val.dataset.min ? parseInt(val.dataset.min) : 1, max = parseInt(val.dataset.max) || 100;

    switch (val.type) {
      case 'checkbox': {
        if (!exclude.includes(key)) {

          if (!val.checked && min) {
            arr.push(val.name);
            val.classList.add('alert');
            data[val.name] = 0;
          } else data[val.name] = 1;
        } else data[val.name] = val.checked ? 1 : 0;
        break;
      }
      case 'radio': {
        if (val.checked) data[val.name] = parseInt(val.value);
        break;
      }
      default: {
        const msg = val.previousElementSibling ? val.previousElementSibling.textContent.replace(":", "") : val.name;

        switch (val.name) {
          case 'g-recaptcha-response':
            data[val.name] = sanitize(val.value)
            break;
          case 'email':
            if (!is_email(val.value)) {
              arr = _addError(arr, val, val.dataset.error || `Invalid ${msg.toLowerCase()}`, key);
            } else data[val.name] = sanitize(val.value);
            break;

          case 'zip':
          case 'adr_zip':
          case 'zip2':
            if (!_validateLength(val, min, max)) {
              arr = _addError(arr, val, val.dataset.error || `Invalid ${msg.toLowerCase()}`, key);
            } else data[val.name] = sanitize(val.value);
            break;

          case 'phone':
          case 'tel':
          case 'num':
            const num = val.value.replace(" ", "");
            if (!is_tel(num)) arr = _addError(arr, val, val.dataset.error || `Invalid format, available only number +,0-9`, key);
            else if (!_validateLength(val, min, 15)) arr = _addError(arr, val, `Min. ${min} chars`, key);
            else data[val.name] = parseInt(num);
            break;

          case 'quantity':
            if (!_validateLength(val, 1, config.quantity_max)) {
              arr = _addError(arr, val, val.dataset.error || `Min. chars: ${min}`, key);
            } else data[val.name] = parseInt(val.value);
            break;

          case 'add_fav':
            targetBtn.classList.contains('act') ? data[val.name] = 0 : data[val.name] = 1;
            targetBtn.classList.toggle('act');
            break;

          case 'nip':
            if (!is_nip(val.value) && !exclude.includes(key) && min !== 0 || (!is_nip(val.value) && min === 0 && val.value.length !== 0)) {
              arr = _addError(arr, val, val.dataset.error || `Invalid ${msg.toUpperCase()}`, key);
            } else data[val.name] = parseInt(sanitize(val.value));
            break;

          case 'set_pass':
            if (!_validateLength(val, min, max)) {
              arr = _addError(arr, val, val.dataset.error || `Min. chars: ${min}`, key);
            } else data[val.name] = encodeURIComponent(val.value);

            if (val.value.length && !is_val(val.value) && val.name.indexOf('pass') < 0 && val.name.indexOf('company') < 0) arr = _addError(arr, val, `Niedozwolone znaki: & { } / " \\' ?!`, key);
            break;
          case 'pass':
          case 'pass2':
            if (val.value.length && !is_val(val.value) && val.name.indexOf('pass') < 0 && val.name.indexOf('company') < 0) arr = _addError(arr, val, `Niedozwolone znaki: & { } / " \\' ?!`, key);
            arr = _validatePass(val, arr, data, min, max);
            break;

          default:
            if (!exclude.includes(key)) {
              if (!_validateLength(val, min, max)) {
                arr = _addError(arr, val, val.dataset.error || `Min. chars: ${min}`, key);
              } else data[val.name] = sanitize(val.value);
            } else data[val.name] = sanitize(val.value);

        }
      }
    }

  });

  //console.log(arr); // input error test
  return arr.length;
}

export function _validatePass(el, arr, data, min = 8, max = 60) {
  let els, i = 0;

  el.classList.remove('alert');
  del(el.parentNode.querySelector(`[data-key="warn_ps${el.name}"]`));

  if (!_validateLength(el, min, max)) arr = _addError(arr, el, el.dataset.error || `Min. chars: ${min}`, '_ps' + el.name);

  if (el.name === 'pass2') {
    if (!els) els = el.form.querySelectorAll('input[data-ps]');
    for (i; i < els.length; i++) els[0].value === els[1].value ? data[els[i].name] = encodeURIComponent(els[i].value) : arr = _addError(arr, els[i], `Hasła muszą być identyczne`, '_ps' + els[i].name);
  }

  if (sanitize(el.value) !== el.value) arr = _addError(arr, el, el.dataset.error2 || `Hasło zawiera niedozwolone znaki: & { } / " \\' ?`, '_ps' + el.name);

  return arr;
}

export function _addError(arr, val, msg, key) {
  arr.push(val.name);
  val.classList.add('alert');
  render(val.parentNode, `<small class="inbl txt-right c--alert${val.parentNode.classList.contains('rel') ? ' abs bg--wh ' : ' row txt-right '}bottom-15 right0 uxs mt5" data-key="warn${key}">${msg}</small>`);
  return arr;
}

export function _validateLength(val, min, max) {
  return val.value.length < min || val.value.length > max ? false : true;
}

export function _observe(obj, callback, data = {}) {
  if (!obj || typeof callback !== 'function') return;

  let cfg = Object.assign({
    data: null,
    get_callback: null,
    del_callback: null
  }, data);

  let handler = {
    set(target, property, value) {
      if (target[property] !== value) {
        callback(property, value, {data: cfg.data, target: target});
        target[property] = value;
      }
      return true;
    },
    get(target, property) {
      typeof data.get_callback === 'function' && data.get_callback(target, property);
      return target[property];
    }
  };

  if (typeof (Proxy) === 'function') {
    handler.deleteProperty = (target, property) => {
      delete target[property], typeof data.del_callback === 'function' && data.del_callback(target, property);
    };
    return new Proxy(obj, handler)
  }
  return Proxy_(obj, handler)
}

export function _modal(msg, cfg = {}) {
  const obj = Object.assign({
    key: 'msg',
    color: 'def',
    time: .3,
    type: 0,
    padding: '1em 1.5em 0',
    wrap: 'fix row h100 left0 top0 bg--pop',
    btn: 'OK',
    btnClass: 'btn',
    fontSize: '1.2em',
    align: 'left',
    width: '500px',
    clear: true,
    scrollable: true,
    cb: null
  }, cfg);

  loadlib('js/gsap.js', 'TweenLite', obj.fn = () => {
    let pop, clickEv = (e) => {
      e.preventDefault();
      if (typeof obj.cb === 'function') obj.cb();
      e.target.removeEventListener('click', clickEv);
      clickEv = null;
      del(pop);
    };

    TweenLite.fromTo(pop = render(document.body, `<div class="pop z1000 ${obj.wrap} flex loaded justify-center align-center"><div class="flex rel fw400 flex-wrap border bg--w shad justify-start lg txt-${obj.align} row contentBox" style="text-align:left;padding:${obj.padding};max-width:${obj.width}"><div class="row txt-center ${obj.scrollable ? 'scroll' : ''} c--${obj.color} row mt25 mb25"><div class="row" style="font-size:${obj.fontSize}">${msg}</div>${obj.btn.length ? `<button class="mt30 close btn--turk ${obj.btnClass}" style="min-width:initial">${obj.btn}</button>` : ''}</div></div></div>`, {key: obj.key}), obj.time, {autoAlpha: 0}, {autoAlpha: 1});
    each(pop.getElementsByClassName('close'), (key, val) => {
      val.onclick = clickEv;
    });

    return pop;
  });
}

export function _request(url, data, cfg = {}) {
  let controller = new AbortController(), obj = Object.assign({
    signal: controller.signal,
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    //'Accept': 'application/json, application/xml, text/plain, text/html, application/pdf, image/jpeg, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //'Content-Type': 'application/json', //multipart/form-data, application/json, application/x-www-form-urlencoded
    headers: {
      'Content-Type': typeof cfg.type !== 'undefined' ? cfg.type : 'application/json'
    },
    body: data,
    cb: null,
  }, cfg);

  obj.type && obj.type.indexOf('multipart') > -1 && delete obj.headers;

  fetch(url, obj).then(response => {
    response.status !== 200 && controller.abort();
    typeof obj.cb === 'function' && obj.cb(response);
  });
}

export function _tooltip(text = '', tooltip = null, time = 2) {
  hide(tooltip, 0.2, true);
  loadlib('js/gsap.js', 'TweenLite', () => {
    TweenLite.fromTo(tooltip = render(document.body, `<div class="fix bottom30 shad z800 right30 inbl w-auto padl20 padr20 padt20 padb20 bg--w c--dbr" style="border-radius:5px">${text}</div>`), 0.3, {
      autoAlpha: 0,
      x: '100%'
    }, {autoAlpha: 1, x: '0%'});

    let tout = setTimeout(() => {
      hide(tooltip, 0.2, true);
      tout = null;
      tooltip = null;
    }, time * 1000);
  });
  return tooltip;
}