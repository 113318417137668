import {_request} from "../functions";

class api {
  constructor() {
    if (!this.setVars()) return false
    this.setEvents()
  }

  setVars() {
    this.forms = document.body.querySelectorAll('form[data-api]')
    return this.forms.length
  }

  setEvents() {
    let data = {}
    each(this.forms, (key, val) => {
      val.addEventListener('submit', (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!val.length) return
        each(val, (i, field) => {
          switch (field.type) {
            case 'checkbox':
              if (field.name) data[`${field.name}`] = field.checked;
              break;
            default:
              if (field.name) data[`${field.name}`] = field.value;
          }
        })

        if(!data._token) data._token = csrf

        if (!val.dataset.api) return
        _request(val.dataset.api, JSON.stringify(data), {
          cb: (xhr) => {
            xhr.json().then(response => {
              response.msg ? this.modal(response, () => {
                  this.redirect(response)
                }) : this.redirect(response)
            })
          }
        });
      })
    })
  }

  redirect(response) {
    if(!response.redirectUri) return;
    window.location.href = response.redirectUri;
  }

  modal(response = '', cb) {
    let modal = render(document.body, `<div id="modal" class="row modal h100 fix top0 left0 flex align-center justify-center" style="z-index:900">
  <div class="shad ${!response.status ? 'error' : ''}" style="max-width:470px;width:100%;padding:2em;border-radius:15px;background-color:#fff">
    <h6 style="font-size:1em;font-weight:300">${response.msg}</h6>
    <button class="btn btn--sm mt30">OK</button>
  </div>
</div>`)
    let btn = modal.getElementsByTagName('button')[0]
    btn && btn.addEventListener('click', () => {
      del(modal)
      if(typeof cb === 'function') cb()
    })
  }
}

export default api
